$main-font-family: 'Prompt';
$alt-font-family: 'sans serif';

$giallo: #ffd500; //rgb(255, 213, 0)
$nero: #000;
$nero-medio: #101820;
$nero-scuro: #0d131a;
$nero-chiaro: #16212c;
$bianco: #fff;
$rosso: #fe0000;

$bianco-rosa: #ffd5ff;
$grigio: rgb(87, 87, 86);
$grigio-scuro: rgb(88, 88, 90);
$grigio-medio: rgb(136, 139, 179);
$grigio-chiaro: rgb(216, 216, 218);
$bianco-sporco: rgb(246, 246, 246);
$arancione: rgb(242, 99, 52);

$text-color: $nero;
$text-reverse: #084c60;
$text-highlight: $rosso;
$text-disabled: $grigio-chiaro;
$text-error: red;
$text-black: #000;
$text-dark: #3d3d3d;
$text-form-color: $bianco;

$main-header-bg: transparent;
$main-header-color: $bianco;

$link-color: $bianco;
$line-color: $bianco-rosa;

$background-color: #084c60;
$background-highlight: $rosso;
$background-reverse: $bianco;

$selection-background: $nero-medio;
$selection-color: $bianco;

$placeholder-color: $text-dark;
$placeholder-weight: normal;
$placeholder-font-size: 16px;
$placeholder-font-style: normal;

$dark-background: rgba(0, 0, 0, 0.5);
$light-background: rgba(255, 255, 255, 0.7);
$blue-background: rgba(0, 56, 101, 0.95);
$white-background: rgb(255, 255, 255);
$black-background: rgb(0, 0, 0);

// AV LIVE BACKGROUNDS
$av-live-background: transparent;
$av-content-background: transparent;
$av-aside-background: transparent;
$room-box-background: $white-background;
$av-live-frame-border: $dark-background;

$checkbox-color: $text-color;
$checkbox-bg: $nero;

$msg-confirm-color: $text-highlight;
$msg-confirm-bg: $nero;

$button-color: $bianco;

$star-color: gold;

$survey-disabled-arrow: #5c5c5c;

$text-strong: bold;
$text-bold: 700;
$text-semibold: 600;
$text-regular: 400;
$text-light: 300;
$text-thin: 200;

.dummy  {
    color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 950px) {
}

/*

$text-reverse: #212121;
$text-inverse: #eeeeee;
$text-highlight: $color-2;
$text-error: red;

$placeholder-color: rgba(143, 143, 143, 0.6);


*/

/**
 * ----------------------------------------
 * animation slide-in-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(100%);
        transform: translateZ(-1400px) translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(100%);
        transform: translateZ(-1400px) translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}
