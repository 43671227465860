.btn-form-submit {
    font-family: 'Michroma';
    color: white;
    background-color: $background-color;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    font-weight: $text-bold;
    display: inline-block;
    // border: 1px solid $text-highlight;

    &:hover,
    &:active {
        font-weight: bold;
        color: white;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }
    &:focus,
    &:active {
        transform: scale(0.95);
        // color: $text-reverse;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }
}

// buttons for languages
ul.header__languages.d-flex {
    justify-content: end;
    @media screen and (min-width: 980px) {
        justify-content: center;
        margin-bottom: 0;
    }

    li {
        margin: 0 0 0 25px;

        div {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 45px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0.5;
            &.lang-active {
                opacity: 1 !important;
            }
            &:hover {
                opacity: 1 !important;
            }
        }
    }
}
