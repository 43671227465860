#time2go {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    padding-top: 36px;
    //	padding: 15px;
    max-width: 540px;
    margin: 0 auto;

    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        color: $background-color;
        //padding: 0 12px;
        //max-width: 160px;

        .short {
            display: none;
        }

        .long {
            text-transform: capitalize;
            font-size: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '';
            }
        }
        // &:first-child {

        //     .t2-digit:after {
        //         content: '';
        //     }
        // }
    }
    .t2-part {
        line-height: 1;
        //background-color: $text-color;
        color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .long {
            text-transform: uppercase;
        }
    }

    .t2-digit {
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 10px;
        position: relative;

        // &:after {
        //     content: ':';
        //     position: absolute;
        //     right: -5px;
        // }
    }
}

@media (min-width: 375px) {
    #time2go {
        .t2-counter {
            //padding: 0 15px;

            .long {
                font-size: 16px;
            }
        }

        .t2-digit {
            font-size: 48px;
            width: 80px;

            &:after {
            }
        }
    }
}

@media (min-width: 540px) {
    #time2go {
        //padding: 25px 5vw;
        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: 14px;
            }
        }

        .t2-digit {
            font-size: 60px;
            width: 100px;

            &:after {
            }
        }
    }
}

@media (min-width: 768px) {
    #time2go {
        //padding: 50px 10vw;

        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: 24px;
            }
        }

        .t2-digit {
            font-size: 90px;

            &:after {
            }
        }
    }
}

@media (min-width: 992px) {
    #time2go {
        //padding: 25px 0;

        .t2-counter {
            //padding: 0 35px;
            width: 100%;

            .long {
                font-size: 30px;
            }
        }

        .t2-digit {
            font-size: 84px;
            width: 180px;

            &:after {
                right: -20px;
            }
        }
    }
}

@media (min-width: 1200px) {
    #time2go {
        .t2-counter {
            //  width: 190px;
        }
    }
}
