@font-face {
    font-family: 'Michroma';
    src:
        url('../fonts/Michroma-Regular.woff2') format('woff2'),
        url('../fonts/Michroma-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Prompt';
    src:
        url('../fonts/Prompt-italic.woff2') format('woff2'),
        url('../fonts/Prompt-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Prompt';
    src:
        url('../fonts/Prompt-Regular.woff2') format('woff2'),
        url('../fonts/Prompt-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
